import { css } from 'emotion'
import { white, darkGray, pinkRed, gray, lightGray } from 'constants/colors'

export const stack = css`
    --space: 60px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    & > * {
        margin-top: 0;
        margin-bottom: 0;
        margin-left: auto;
        margin-right: auto;
    }

    & > * + * {
        margin-top: var(--space);
        margin-bottom: var(--space);
    }
`

const darkTheme = css`
    --theme-background: ${darkGray};
    --theme-color: ${lightGray};
    --theme-link-color: ${pinkRed};
`

const lightTheme = css`
    --theme-background: ${white};
    --theme-color: ${darkGray};
    --theme-link-color: ${pinkRed};
`

export const theme = css`
    @media (prefers-color-scheme: dark) {
        ${darkTheme}
    }

    @media (prefers-color-scheme: light) {
        ${lightTheme}
    }

    background-color: var(--theme-background);
    color: var(--theme-color);
    a {
        color: var(--theme-link-color);
    }
`

export const boxedLayoutWidth = '80%'
