import React from 'react'
import { css } from 'emotion'
import { range } from 'lodash'

import Header from 'components/Header'
import Footer from 'components/Footer'
import Background from 'components/Background'
import SEO from 'components/SEO'
import { theme, boxedLayoutWidth } from 'constants/style'

import 'constants/style.css'

const layout = css`
    ${theme}
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
`

const container = css`
    flex: auto;
    padding: 10px;
    margin: 0px auto 0;
`

const overlay = css`
    background-color: rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(5px);
`

const Overlay = () => {
    return <div></div>
}

const Page = ({ title = 'Index', children, boxed = true, ...props }) => {
    return (
        <>
            <SEO title={title} />
            <div className={layout}>
                <Header boxed={boxed} />
                <main
                    className={container}
                    style={{ width: boxed ? boxedLayoutWidth : '100%' }}
                >
                    {children}
                </main>
                <Footer boxed={boxed} />
            </div>
        </>
    )
}

export default Page
