import React from 'react'
import { css } from 'emotion'

import { boxedLayoutWidth } from 'constants/style'

const footerStyle = css`
  color: rgba(150, 150, 150, 0.9);
  font-size: 14px;
  padding: 20px 0px;
  height: 80px;
  margin: 0 auto;
`

const Footer = ({ boxed = false }) => {
  return (
    <footer
      className={footerStyle}
      style={{ width: boxed ? boxedLayoutWidth : '100%' }}
    >
      © {new Date().getFullYear()}. All rights reserved
    </footer>
  )
}

export default Footer
