import React from 'react'
import { Link } from 'gatsby'
import { css } from 'emotion'

import { boxedLayoutWidth } from 'constants/style'

const header = css`
    padding: 20px 0px;
    h1 {
        margin-bottom: 0px;
        color: rgba(0, 0, 0, 0.3);
    }
    display: flex;
    justify-content: space-between;
    align-items: center;
`

const navbar = css`
    display: flex;
    align-items: flex-end;
    a {
        margin: 0 20px;
    }
`

function Header({ boxed }) {
    return (
        <div
            className={header}
            style={{
                margin: '0 auto',
                width: boxed ? boxedLayoutWidth : '100%',
            }}
        >
            <Link to="/">
                <h1>DM</h1>
            </Link>
            <nav className={navbar}>
                <Link to="/about">About</Link>
                <a href="https://dev.to/nopestack/" target="blank">
                    Blog
                </a>
                <Link to="/projects">Projects</Link>
            </nav>
        </div>
    )
}

export default Header
