import React from 'react'
import { css } from 'emotion'

const bg = css`
  background: url('/assets/img/backdrop.jpg');
  background-size: cover;
  background-position: center center;
  background-attachment: fixed;
  position: absolute;
  height: 130vh;
  width: 100%;
`

export default function Background() {
  return <div className={bg}></div>
}
